let subnavDropdown = document.querySelector(".subnav-m .nav-sub-name");
let pageBanner = document.querySelector("#page-banner");
let sublinkItems = document.querySelectorAll(".sublink-item");
let subnavName = document.querySelector(".nav-sub-name");

if (subnavDropdown) {
  subnavDropdown.addEventListener("click", () => {
    if (pageBanner.classList.contains("sub-expanded")) {
      pageBanner.classList.remove("sub-expanded");
    } else {
      pageBanner.classList.add("sub-expanded");
    }
  });

  sublinkItems.forEach((link) => {
    link.addEventListener("click", () => {
      if (pageBanner.classList.contains("sub-expanded")) {
        pageBanner.classList.remove("sub-expanded");
        subnavName.innerHTML = link.innerHTML;
      }
    });
  });
}
