export const initVideoTracking = (video, videoTitle) => {

  let videoPlayer = video,
    videoPlaying = false,
    videoDuration = 0;
  window.videoProgress = 0;


  videoPlayer.on('play', () => {

    videoPlaying = true;

    videoPlayer.getDuration().then((seconds) => {
      videoDuration = seconds;
    });

    videoPlayer.getCurrentTime().then((seconds) => {
      let currentTime = seconds;
      if (currentTime < 1) {
        dataLayerPush('Customevent: Video', 'Start', videoTitle, 'Video');
        window.videoProgress = 0;
      } else {
        dataLayerPush('Customevent: Video', 'Play', videoTitle, 'Video');
      }

      checkVideo(videoPlayer, videoTitle, videoDuration, window.videoProgress, videoPlaying);
    });

  });


  videoPlayer.on('pause', () => {
    videoPlaying = false;
    dataLayerPush('Customevent: Video', 'Pause', videoTitle, 'Video');
  });
  videoPlayer.on('ended', () => {
    videoPlaying = false;
    dataLayerPush('Customevent: Video', 'Completed', videoTitle, 'Video');
    window.videoProgress = 0;
    videoPlayer.setCurrentTime(0);
  });
}

const checkVideo = (video, videoTitle, videoDuration, videoProgress, videoPlaying) => {

  video.getCurrentTime().then((seconds) => {
    let currentTime = seconds;
    let progress = 0;

    if (videoDuration / currentTime < 4) {
      progress = '25%';
    }
    if (videoDuration / currentTime < 2) {
      progress = '50%';
    }
    if (videoDuration / currentTime < 1.33) {
      progress = '75%';
    }

    if (progress !== 0 && window.videoProgress != progress) {
      window.videoProgress = progress;
      trackVideo(progress, videoTitle);
    }

    if (videoPlaying) setTimeout(() => {
      checkVideo(video, videoTitle, videoDuration, window.videoProgress, videoPlaying)
    }, 1000);
  })

}

const trackVideo = (val, title) => {
  dataLayerPush('Customevent: Video', val, title, 'Video');
}

const dataLayerPush = (evt, action, label, category) => {


  dataLayer.push({
    event: evt,
    attributes: {
      eventAction: action,
      eventLabel: label,
      eventCategory: category
    }
  });
  console.log('evt: ' + evt + ' | action: ' + action + ' | label: ' + label + ' | category: ' + category)

}