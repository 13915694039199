const defaultProps = {
  defaultHeight: 200,
  collapsedHeight: 100,
  expandedOffset: 200,
  collapseOnScroll: false,
};

let scrollPosition = window.scrollY;

const handleScrollLock = () => window.scrollTo(0, scrollPosition);

export default class FloatingISI {
  constructor(element, props = {}) {
    if (!element) {
      throw new Error("%cComponentsHC %cInvalid target node", "font-weight: bold");
    }

    this.isi = element;
    this.isScrolling = false;
    this.scrollTime = Date.now();
    this.props = Object.assign(defaultProps, props);
    this.clone = true;
    this.status = "default";
    this.height = this.props.defaultHeight;
    this.state = this.status;
    document.addEventListener("DOMContentLoaded", () => {
      setInterval(() => this.init(), 50);
      this.isi.dispatchEvent(
        new CustomEvent("ready", {
          detail: this,
        })
      );
    });
  }

  init() {
    scrollPosition = window.scrollY;
    const bounds = this.isi.getBoundingClientRect();
    //have the mobile bottom nav re-appear after the page was scrolled down to the ISI and scrolled back up again
    if (bounds.top - window.innerHeight + this.height <= 0) {
      if (this.status) this.state = false;
    } else {
      if (this.status === "expanded") {
        window.addEventListener("scroll", handleScrollLock, {});
        return;
      } else {
        window.removeEventListener("scroll", handleScrollLock);
      }
      if (this.props.collapseOnScroll) {
        if (window.scrollY <= 5) {
          if (this.status !== "default") {
            this.state = "default";
          }
        } else {
          if (this.status !== "collapsed") {
            this.state = "collapsed";
          }
        }
      } else {
        if (this.status !== "default") {
          this.state = "default";
        }
      }
    }
  }

  scrollTo(offset = 0) {
    const bounds = this.isi.getBoundingClientRect();
    window.scrollBy(0, bounds.top - offset);
  }

  set state(status) {
    if (
      !this.isi.dispatchEvent(
        new CustomEvent(status, {
          cancelable: true,
          detail: this,
        })
      )
    ) {
      return false;
    }

    switch (status) {
      case false:
        if (
          !this.isi.dispatchEvent(
            new CustomEvent("destroy", {
              cancelable: true,
              detail: this,
            })
          )
        ) {
          return false;
        }
        this.clone = false;
        status = false;
        this.isi.dispatchEvent(
          new CustomEvent("change", {
            detail: this,
          })
        );
        if (Array.from(document.querySelectorAll(".isi-toggle-container.container")).length > 1)
          Array.from(document.querySelectorAll(".isi-toggle-container.container"))[1].setAttribute(
            "aria-expanded",
            "true"
          );
        document.querySelector("#top-notifications-banner").classList.add("scrolled");
        break;
      case "expanded":
        this.clone = true;
        this.isi.dispatchEvent(
          new CustomEvent("change", {
            detail: this,
          })
        );
        if (Array.from(document.querySelectorAll(".isi-toggle-container.container")).length > 1)
          Array.from(document.querySelectorAll(".isi-toggle-container.container"))[1].setAttribute(
            "aria-expanded",
            "true"
          );
        break;
      case "collapsed":
        this.clone = true;
        this.isi.dispatchEvent(
          new CustomEvent("change", {
            detail: this,
          })
        );
        if (Array.from(document.querySelectorAll(".isi-toggle-container.container")).length > 1)
          Array.from(document.querySelectorAll(".isi-toggle-container.container"))[1].setAttribute(
            "aria-expanded",
            "false"
          );
        document.querySelector("#top-notifications-banner").classList.remove("scrolled");
        break;
      default:
        this.clone = true;
        status = "default";
        this.isi.dispatchEvent(
          new CustomEvent("change", {
            detail: this,
          })
        );
        if (Array.from(document.querySelectorAll(".isi-toggle-container.container")).length > 1)
          Array.from(document.querySelectorAll(".isi-toggle-container.container"))[1].setAttribute(
            "aria-expanded",
            "false"
          );
        document.querySelector("#top-notifications-banner").classList.remove("scrolled");

        break;
    }

    this.status = status;
  }

  set clone(visible) {
    if (!visible) {
      if (!this.clone || !this.clone.parentNode) return;
      this.clone.parentNode.removeChild(this.clone);
    } else {
      if (!Array.from(document.body.children).find((v) => v === this.clone)) {
        document.body.appendChild(this.clone);
      }
    }
  }

  get clone() {
    let elem = document.querySelector('[data-component="FloatingISI"]');
    if (elem) {
      return elem;
    } else {
      const cl = this.isi.cloneNode(true);
      cl.classList.add("floating-isi");
      cl.classList.add("isi");
      cl.classList.add("floating-isi-clone");
      cl.setAttribute("data-component", "FloatingISI");
      cl.classList.remove("isi-not-clone");
      return cl;
    }
  }

  on(listener, cb) {
    return this.isi.addEventListener(listener, cb);
  }
}
