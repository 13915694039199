import FloatingISI from "./floating-isi.componentshc";

import Modal from "./componentshc-modal";

import "./videoplayer";
import "./subnav";

let floatingISIDefaultHeight;
let floatingISICloseHeight;

////////////////////////////
// ISI floating functions //
////////////////////////////

const addFloatingIsiFunctions = () => {
  let getISIDefaultHeight = () => {
    let floatingISI = document.querySelector(".floating-isi-row");
    return floatingISI.offsetHeight;
  };

  floatingISIDefaultHeight = getISIDefaultHeight();

  const isi = new FloatingISI(document.querySelector(".isi-el"), {
    defaultHeight: floatingISIDefaultHeight,
  });

  document.querySelector(".isi-el").addEventListener("default", addFloatingIsiFunctions);

  let floatingisicontainer = document.querySelector(".floating-isi-clone");
  let isiBtnExpands = document.querySelectorAll(".isi-toggle-container button.isi-expand");
  let isiBtnCloses = document.querySelectorAll(".isi-toggle-container .isi-close");
  let isiopen = "default";

  isiBtnExpands.forEach((btnExpand) => {
    btnExpand.addEventListener("click", (e) => {
      if (!isiopen || isiopen === "default") {
        floatingisicontainer.classList.add("floating-isi-expanded");
        floatingisicontainer.classList.remove("floating-isi-close");
        isi.state = "expanded";
        isiopen = true;
        document.body.classList.add("show-isi");
      }
    });
  });

  isiBtnCloses.forEach((btnclose) => {
    btnclose.addEventListener("click", (e) => {
      if (isiopen || isiopen === "default") {
        floatingisicontainer.classList.add("floating-isi-close");
        floatingisicontainer.classList.remove("floating-isi-expanded");
        isi.state = "collapsed";
        isiopen = false;
        document.body.classList.remove("show-isi");
      }
    });
  });
}; //  END ISI floating functions

addFloatingIsiFunctions();

////////////////////////////////////////////
// Add 'active' class to subnav on scroll //
////////////////////////////////////////////
import scrollSpy from "./scrollspy";

import { initVideoTracking } from "./vimeo-tracking";

// Global variables
let body = document.body, // Chrome, Firefox, IE and Opera places the overflow at the <html> level, unless else is specified.
  formName,
  subnav = document.getElementById("subnav"),
  vimeoIframe,
  vimeoPlayer;

window.onload = () => {
  init();
};

const init = () => {
  // unique GTM category on support page for one footer link
  let supportPageFooterInbraceLink = document.querySelector(
    '.Support [href="http://Inbracesupportprogram.com"]'
  );
  if (supportPageFooterInbraceLink) {
    supportPageFooterInbraceLink.className = "gtm-cta";
  }

  // Init functions
  cookieBanner();
  stickyNav();
  mobileNav();
  isiBodyClass();
  initModals();
  checkUserAgent(); // font renders slightly different between Mac/PC so check for OS

  window.addEventListener("keydown", (e) => {
    if (e.key == "Escape") {
      let closeModal = document.querySelector(".modal__close-button");
      if (closeModal) {
        closeModal.click();
      }
      let closeMenu = document.querySelector("#navbar-toggler.active");
      if (closeMenu) {
        closeMenu.click();
        closeMenu.focus();
      }
    }
  });

  // When Modal is open tab will only be able to move with in the modal
  window.addEventListener("keydown", (e) => {
    var firstFocusableEl = document.querySelector("aside");
    var lastFocusableEl = document.querySelector(".modal__close-button");

    if (firstFocusableEl) {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          /* shift + tab */
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else {
          /* tab */
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      }
    }
  });

  // When mobile menu is open will only be able to move within the mobile menu
  window.addEventListener("keydown", (e) => {
    var closebutton = document.querySelector("#navbar-toggler");
    var firstMenuLink = document.querySelector('[data-gtm-event-label="Impact of COMT"]');
    var lastMenuLink = document.querySelector("nav .unstyled:last-child a");

    if (closebutton.classList.contains("active")) {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          /* shift + tab */
          if (document.activeElement === firstMenuLink) {
            closebutton.focus();
            e.preventDefault();
          }

          if (document.activeElement === closebutton) {
            lastMenuLink.focus();
            e.preventDefault();
          }
        } else {
          /* tab */
          if (document.activeElement === closebutton) {
            firstMenuLink.focus();
            e.preventDefault();
          }

          if (document.activeElement === lastMenuLink) {
            closebutton.focus();
            e.preventDefault();
          }
        }
      }
    }
  });

  // Scrollspy options to add active state to subnav on scroll
  const options = {
    sectionClass: ".scrollspy", // Query selector to your sections
    menuActiveTarget: ".sublink-item", // Query selector to your elements that will be added `active` class
    offset: 100, // Menu item will active before scroll to a matched section 100px
  };

  // Run only if there's a subnav
  if (subnav) {
    //====DISABLE THIS FOR SCREENSHOTS FOR IP
    if (window.location.hash == "#screenshot") return;
    //====
    scrollSpy(document.getElementById("section-nav"), options);
    scrollSpy(document.getElementById("sublinks"), options);
    scrollSpy(document.getElementById("page-content"), options);
    scrollToSection();
  }

  if (isMobileSize()) {
    document.querySelector("#primary-nav .nav").setAttribute("data-hidden", "true");
  }
  //hack to update hash and update mobiel subnav selector and add hash
  window.addEventListener("scroll", () => {
    selecSubItemAddHash();
  });
  selecSubItemAddHash();

  window.addEventListener("resize", resize);
  resize();
};

const selecSubItemAddHash = () => {
  //====DISABLE THIS FOR SCREENSHOTS FOR IP
  if (window.location.hash == "#screenshot") return;
  //====

  if (!document.querySelector("#section-nav .sublink-item.active")) return;

  let target = document.querySelector("#section-nav .sublink-item.active").getAttribute("href");
  let items = document.querySelectorAll("nav .sublink-item");
  items.forEach((el) => {
    let ar = el.getAttribute("href").split("/");
    let h = ar.pop();
    el.classList.remove("active");
    if (h == target) {
      el.classList.add("active");
      // window.location.hash = h.replace('#', '');
      document.querySelector("#page-banner p.nav-sub-name").innerText = document.querySelector(
        "#section-nav .sublink-item.active"
      ).textContent;
    }
  });
};

const resize = () => {
  if (isMobile()) {
    document.body.classList.add("is-mobile");
  } else {
    document.body.classList.remove("is-mobile");
  }

  // Run only if on Desktop and there's a subnav

  if (subnav && !isMobileSize()) {
    scrollToSection(); /* mobile bug: Safari mobile browser resizes to show browser icons when scrolling to the top/bottom. Disable scrolltosection on mobile resizing to remove unnecessary section scrolling */
  }
};

//////////////////////////
//Video Player Tracking //
//////////////////////////

function delayVideoFunctions() {
  setTimeout(function () {
    // Wait for video modal to load first
    vimeoIframe = document.querySelector(".video-iframe");
    vimeoPlayer = new Vimeo.Player(vimeoIframe);
    let videoTitle = vimeoIframe.dataset.videoTitle;

    vimeoPlayer.play(); // Autoplay parameters already added to video embed code but most mobile devices do not allow autoplay. Workaround: initial modal click does not autoplay but this code triggers play in mobile thereafter

    initVideoTracking(vimeoPlayer, videoTitle); // vimeo-tracking.js
    let videoContainer,
      videoModal = vimeoIframe.parentNode.parentNode.parentNode.parentNode,
      windowHeight;
    if (isMobileSize()) {
      if (!isMobile()) {
        videoContainer = document.querySelector(".embed-container");

        if (videoModal.offsetHeight > window.innerHeight) {
          videoModal.style.width =
            videoContainer.offsetWidth / (videoContainer.offsetHeight / window.innerHeight) + "px";
        }
      }
    } else {
      videoContainer = document.querySelector(".embed-container");

      windowHeight =
        window.innerHeight - document.querySelector(".isi-el.floating-isi").offsetHeight;
      if (videoModal.offsetHeight > windowHeight) {
        videoModal.style.width =
          videoContainer.offsetWidth / (videoContainer.offsetHeight / windowHeight) + "px";
      }
    }
  }, 500);
}

// Runs when we are on the video page and there is a iframe on the page
if (
  window.location.toString().indexOf("/videos/") > -1 &&
  document.querySelector(".video-iframe")
) {
  delayVideoFunctions();
}

/////////////////
// Init Modals //
/////////////////
const initModals = () => {
  var modal1 = document.getElementById("modal-1"),
    modal2 = document.getElementById("modal-2"),
    modal3 = document.getElementById("modal-3"),
    exitModal = document.getElementById("exit-modal"),
    videoModal = document.getElementById("video-modal"),
    videoModal1 = document.getElementById("video-modal-1"),
    videoModal2 = document.getElementById("video-modal-2"),
    videoModal3 = document.getElementById("video-modal-3");

  if (modal1) {
    const modal = new Modal({
      target: modal1,
    });
  }
  if (modal2) {
    const modal = new Modal({
      target: modal2,
    });
  }
  if (modal3) {
    const modal = new Modal({
      target: modal3,
    });
  }

  if (videoModal) {
    const modal = new Modal({
      target: videoModal,
    });

    let videoModalTrigger = document.querySelectorAll('[data-modal-open="video-modal"]');

    Array.from(videoModalTrigger).forEach(function (link) {
      link.addEventListener("click", delayVideoFunctions);
    });
  }

  if (videoModal1) {
    const modal = new Modal({
      target: videoModal1,
    });

    let videoModalTrigger = document.querySelectorAll('[data-modal-open="video-modal-1"]');

    Array.from(videoModalTrigger).forEach(function (link) {
      link.addEventListener("click", delayVideoFunctions);
    });
  }

  if (videoModal2) {
    const modal = new Modal({
      target: videoModal2,
    });

    let videoModalTrigger = document.querySelectorAll('[data-modal-open="video-modal-2"]');

    Array.from(videoModalTrigger).forEach(function (link) {
      link.addEventListener("click", delayVideoFunctions);
    });
  }

  if (videoModal3) {
    const modal = new Modal({
      target: videoModal3,
    });

    let videoModalTrigger = document.querySelectorAll('[data-modal-open="video-modal-3"]');

    Array.from(videoModalTrigger).forEach(function (link) {
      link.addEventListener("click", delayVideoFunctions);
    });
  }

  const modal = new Modal({
    target: exitModal,
  });

  let externalLink = document.querySelectorAll('a[data-modal-open="exit-modal"]');

  const dataLayerPush = (label) => {
    dataLayer.push({
      event: "Exit box",
      attributes: {
        eventAction: "Click",
        eventLabel: label,
        eventCategory: "Exit box",
      },
    });
  };

  function showExitInterstitial(event) {
    event.preventDefault();

    setTimeout(function () {
      const stayBtn = document.getElementById("btn-stay");
      const leaveBtn = document.getElementById("btn-leave");
      const url = event.target.href;

      stayBtn.addEventListener("click", (event) => {
        dataLayerPush("Stay Here");
      });

      leaveBtn.addEventListener("click", function () {
        dataLayerPush("Leave Site");
        window.location = url;
      });
    }, 200);
  }

  Array.from(externalLink).forEach(function (link) {
    link.addEventListener("click", showExitInterstitial);
  });
};

const checkUserAgent = function () {
  if (navigator.userAgent.indexOf("Mac OS X") != -1) {
    document.body.classList.add("mac");
  } else {
    document.body.classList.add("pc");
  }
};

const scrollToSection = function () {
  //====DISABLE THIS FOR SCREENSHOTS FOR IP
  if (window.location.hash == "#screenshot") return;
  //====

  let subNavContainerId = "subnav",
    subNavContainer = document.getElementById(subNavContainerId),
    anchorLink = subNavContainer.querySelectorAll(".sublink-item"),
    scrollStartPosition = document.documentElement.scrollTop,
    pageHash = location.hash,
    sectionId = pageHash.replace("#", ""),
    target = document.getElementById(sectionId),
    i;

  // From https://jsfiddle.net/zpu16nen/
  function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return;
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = from + step * (to - from) + unit;
        } else {
          elem.style[style] = from + step * (to - from) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 25);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }

  function scrollTo(navOffset) {
    target = document.getElementById(sectionId);
    if (!target) return;
    scrollStartPosition = document.documentElement.scrollTop;
    animate(
      document.scrollingElement || document.documentElement,
      "scrollTop",
      "",
      scrollStartPosition,
      target.offsetTop - navOffset,
      300,
      true
    );
  }

  // If mobile, nav height is dynamic bc of accordion feature
  if (isMobileSize()) {
    let mobileOffsetHeight = 0;

    for (i = 0; i < anchorLink.length; i++) {
      // let mobileNavHeight = (i * anchorLink[i].offsetHeight) - 1; // include 1px to consider border bottom
      let parentLink = document.querySelector("#primary-nav .active"),
        mainNavSublink = parentLink.parentNode.querySelectorAll(".sublink-item");

      if (pageHash == anchorLink[i].hash) {
        scrollTo(mobileOffsetHeight);
      } else if (pageHash == "#moa-video") {
        scrollTo(30);
      }

      mainNavSublink[i].addEventListener("click", function (e) {
        // sublinks in mobile hamburger menu
        sectionId = this.hash.replace("#", "");
        scrollTo(mobileOffsetHeight);
      });

      anchorLink[i].addEventListener("click", function (e) {
        // sublinks in accordion menu
        sectionId = this.hash.replace("#", "");
        scrollTo(mobileOffsetHeight);
      });
    }
    document.querySelector("#primary-nav .nav");
  } else {
    let desktopOffsetHeight = -5;

    if (pageHash) {
      scrollTo(desktopOffsetHeight);
    }
    Array.from(anchorLink).forEach(function (link) {
      link.addEventListener("click", function () {
        sectionId = this.hash.replace("#", "");
        scrollTo(desktopOffsetHeight);
      });
    });
  }
};

const cookieBanner = function () {
  const closeBtn = document.getElementById("close-cookie");

  closeBtn.addEventListener("click", closeCookieBanner);

  //function setCookie(cname, cvalue, exdays) {
  function setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    const cookieExists = getCookie("cookie-agreement");
    if (!cookieExists) {
      body.classList.add("show");
    }
  }

  function closeCookieBanner() {
    setCookie("cookie-agreement", true);
    body.classList.remove("show");
  }

  checkCookie();
};

const stickyNav = function () {
  // Get the header
  let body = document.querySelector("body"),
    header = document.getElementById("primary-nav"),
    stickyHeader = header.offsetTop,
    cookieBannerHeight = 0;

  // Use the "scroll" version of the nav on homepage to show more hero space
  if (body.id == "home") {
    body.classList.add("scrolled");
    // header.parentNode.classList.add('box-shadow');
  } else {
    document.addEventListener("scroll", addStickyClass);
  }

  if (body.classList.contains("notification")) {
    cookieBannerHeight = document.getElementById("top-notifications-banner").offsetHeight;
    stickyHeader = stickyHeader - cookieBannerHeight;
  }

  // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function addStickyClass() {
    if (window.pageYOffset <= 20) {
      document.body.classList.remove("scrolled");
    } else {
      document.body.classList.add("scrolled");
    }
  }
};

const mobileNav = function () {
  let toggleBtn = document.getElementById("navbar-toggler"),
    nav = document.querySelector("#primary-nav .nav"),
    dropdown = document.querySelectorAll("#primary-nav .dropdown"),
    sublinks = document.querySelectorAll("#primary-nav #sublinks a"),
    body = document.querySelector("body");

  toggleBtn.addEventListener("click", toggleNav);

  Array.from(dropdown).forEach(function (link) {
    link.addEventListener("click", showSublinks);
  });

  function toggleNav() {
    toggleBtn.classList.toggle("active");
    nav.classList.toggle("active");

    if (nav.getAttribute("data-hidden") == "true") {
      nav.setAttribute("data-hidden", "false");
    } else {
      nav.setAttribute("data-hidden", "true");
    }
    if (toggleBtn.getAttribute("aria-expanded") == "false") {
      toggleBtn.setAttribute("aria-expanded", "true");
    } else {
      toggleBtn.setAttribute("aria-expanded", "false");
    }
    body.classList.toggle("mobile-menu");
  }

  function showSublinks(event) {
    event.preventDefault();
    this.parentNode.parentNode.classList.toggle("expanded");
  }

  Array.from(sublinks).forEach(function (link) {
    link.addEventListener("click", closeNav);
  });

  function closeNav() {
    nav.classList.toggle("active");

    toggleBtn.classList.toggle("active");
    body.classList.toggle("mobile-menu");
  }
};

const isiBodyClass = function () {
  let isi = document.getElementById("isi"),
    isiPosY,
    isiInView,
    h;

  document.addEventListener("scroll", addIsiClass);

  // Add body class "isi" when ISI scrolls into window. This is used to hide cookie consent banner in mobile.
  function addIsiClass() {
    if (!isi) return;
    (h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight),
      (isiPosY = isi.offsetTop + floatingISIDefaultHeight), // 188 is height of sticky ISI
      (isiInView = isiPosY - h);

    if (window.pageYOffset >= isiInView) {
      document.body.classList.add("full-isi");
    } else {
      document.body.classList.remove("full-isi");
    }
  }
};

// Helper Methods
const isMobileSize = () => {
  var windowWidth = window.innerWidth,
    mobileBreakpoint = 768,
    mobileSizeCheck = windowWidth < mobileBreakpoint;

  return mobileSizeCheck;
};

const isMobile = () => {
  return screen.width < 768;
};

// Accordion action
let accordionBtn = document.querySelectorAll(".btn-accordian");
accordionBtn.forEach((btn) => {
  btn.addEventListener("click", (e) => {
    let getId = btn.getAttribute("data-accordian-id");
    let accordianContainer = document.querySelector(`#${getId}`);
    let isActive = accordianContainer.classList.contains("active");
    let thisAccordionBody = document.querySelector(`#${getId} .accordian-body-container`);

    if (isActive) {
      accordianContainer.classList.remove("active");
      btn.setAttribute("aria-expanded", "false");
      thisAccordionBody.setAttribute("aria-hidden", "true");
      setTimeout(() => {
        if (!accordianContainer.classList.contains("active")) {
          accordianContainer.classList.remove("expand");
        }
      }, 250);
    } else {
      accordianContainer.classList.add("expand");
      btn.setAttribute("aria-expanded", "true");
      thisAccordionBody.setAttribute("aria-hidden", "false");
      setTimeout(() => {
        if (accordianContainer.classList.contains("expand")) {
          accordianContainer.classList.add("active");
        }
      }, 1);
    }
  });
});
