let closeAllTranscripts = () => {
  let allTranscriptContainer = document.querySelectorAll(".transcript-container");
  allTranscriptContainer.forEach((el) => {
    el.classList.remove("open");
    el.querySelector("button").setAttribute("aria-expanded", "false");
    el.querySelector(".transcript").classList.remove("show");
    setTimeout(() => {
      el.querySelector(".transcript").classList.remove("active");
    }, 200);
  });
};

let openTranscripts = (id) => {
  let transcriptContainer = document.querySelector(`[data-transcript-id="${id}"]`);
  let transcriptButton = document.querySelector(`[data-open-transcript="${id}"]`);

  transcriptContainer.classList.add("open");
  transcriptButton.setAttribute("aria-expanded", "true");
  transcriptContainer.querySelector(".transcript").classList.add("active");
  setTimeout(() => {
    transcriptContainer.querySelector(".transcript").classList.add("show");
  }, 100);
};

window.addEventListener("click", (e) => {
  let activeVideoId = e.target.getAttribute("data-open-transcript");
  if (activeVideoId !== null) {
    if (e.target.getAttribute("aria-expanded") === "false") {
      openTranscripts(activeVideoId);
    } else {
      closeAllTranscripts();
    }
  }
});
