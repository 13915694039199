window.addEventListener("keydown", (e) => {
  if (e.key === "Tab") {
    let collapseBtn = document.querySelector(".floating-isi-clone .close-button");

    // trap focuse to collapse button when isi tray is open
    if (
      document.activeElement === collapseBtn &&
      document.querySelector(".floating-isi-clone").classList.contains("floating-isi-expanded")
    ) {
      e.preventDefault();
    }

    // jump focus from last button to isi tray
    let tabToId = e.target.getAttribute("data-tab-to");
    let expandBtn = document.querySelector(".floating-isi-clone .expand-button");
    let fdaLink = document.querySelector(".isi-not-clone .fda-link");
    if (e.shiftKey) {
      if (document.activeElement === expandBtn) {
        e.preventDefault();
        document.querySelector("[data-tab-to='isi-expand']").focus();
      }

      if (
        document.activeElement === collapseBtn &&
        document.querySelector(".floating-isi-clone").classList.contains("floating-isi-close")
      ) {
        e.preventDefault();
        document.querySelector("[data-tab-to='isi-expand']").focus();
      }
    }

    if (tabToId === "isi-expand") {
      e.preventDefault();
      if (document.querySelector(".floating-isi-clone").classList.contains("floating-isi-close")) {
        collapseBtn.focus();
      } else {
        expandBtn.focus();
      }
    }

    if (
      document.activeElement === collapseBtn &&
      !document.querySelector(".floating-isi-clone").classList.contains("floating-isi-expanded")
    ) {
      e.preventDefault();
      fdaLink.focus();
    }
  }
});
